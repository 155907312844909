import { render, staticRenderFns } from "./status-summary-total-chart.vue?vue&type=template&id=56c756c2&scoped=true&v-if=data&"
import script from "./status-summary-total-chart.ts?vue&type=script&lang=ts&"
export * from "./status-summary-total-chart.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56c756c2",
  null
  
)

export default component.exports